import API from '../repository';
import { AnalyticsGlobalsImpl } from '@/models/Analytics.model';
import { AnalyticsAdvancedImpl } from '@/models/AnalyticsAdvanced.model';

const RESOURCE = '/analytics';

export enum queryDateType {
  Today = 1,
  Yesterday = 2,
  Last7Days = 3,
  last30Days = 4,
}

export interface GetAnalyticsGlobalImp {
  date_type: queryDateType;
}

export interface GetAnalyticsAdvancedImpl {
  from: string;
  to: string;
}

export default {
  async GetAnalyticsGlobal(query: GetAnalyticsGlobalImp) {
    const liteAnalytics: AnalyticsGlobalsImpl = await API.get(`${RESOURCE}/globals`, {
      params: {
        date_type: query.date_type,
      },
    });
    return liteAnalytics;
  },

  async GetAnalyticsAdvanced(query: GetAnalyticsAdvancedImpl) {
    const liteAnalytics: AnalyticsAdvancedImpl[] = await API.get(`${RESOURCE}/advanced`, {
      params: {
        date_from: query.from,
        date_to: query.to,
      },
    });
    return liteAnalytics;
  },
};
