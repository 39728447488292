








































import { Component, Vue } from 'vue-property-decorator';

import analyticsRepository from '@/repository/modules/analytics.repository';
import AnalyticsAdvancedModel, { AnalyticsAdvancedImpl } from '@/models/AnalyticsAdvanced.model';

@Component
export default class SectionTableAnalytics extends Vue {
  date = [
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
  ];
  menu = false;
  rowsAnalitycs: AnalyticsAdvancedImpl[] = [];
  tableHeader = [
    {
      text: 'Country',
      align: 'start',
      value: 'country',
    },
    {
      text: 'Users',
      align: 'start',
      value: 'users',
    },
    {
      text: 'Payments',
      align: 'start',
      value: 'total_payments',
    },
    {
      text: 'C.Rate',
      align: 'start',
      value: 'conversion_rate',
    },
    {
      text: 'A.Payments',
      align: 'start',
      value: 'amount_payments',
    },
    {
      text: 'CB',
      align: 'start',
      value: 'total_chargebacks',
    },
    {
      text: 'A.CB',
      align: 'start',
      value: 'amount_chargebacks',
    },
    {
      text: 'Refunds',
      align: 'start',
      value: 'total_refunds',
    },
    {
      text: 'A.Refunds',
      align: 'start',
      value: 'amount_refunds',
    },
    {
      text: 'Net Amount',
      align: 'start',
      value: 'net_amount',
    },
  ];

  mounted() {
    this.getDataAnalytics();
  }

  get dateRangeText() {
    return this.date.join(' / ');
  }

  async getDataAnalytics() {
    const analytics = await analyticsRepository.GetAnalyticsAdvanced({
      from: this.date[0],
      to: this.date[1],
    });
    this.rowsAnalitycs = analytics;
  }

  get calculateRowsAnalytics() {
    return this.rowsAnalitycs.map((row) => new AnalyticsAdvancedModel(row));
  }
}
