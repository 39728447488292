







import { Component, Vue } from 'vue-property-decorator';
import SectionInfoBlocks from '@/components/Home/SectionInfoBlocks.vue';
import BoxChartView from '@/components/Home/BoxChartView.vue';
import SectionTableAnalytics from '@/components/Home/SectionTableAnalytics.vue';

@Component({
  components: {
    SectionInfoBlocks,
    BoxChartView,
    SectionTableAnalytics,
  },
})
export default class Home extends Vue {}
