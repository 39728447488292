export interface AnalyticsAdvancedImpl {
  users: number;
  total_payments: number;
  total_refunds: number;
  total_chargebacks: number;
  amount_payments: number;
  amount_refunds: number;
  amount_chargebacks: number;
  conversion_rate: string;
  net_amount: number;
  country: string;
}

export default class AnalyticsAdvancedModel implements AnalyticsAdvancedImpl {
  users!: number;
  total_payments!: number;
  total_refunds!: number;
  total_chargebacks!: number;
  amount_payments!: number;
  amount_refunds!: number;
  amount_chargebacks!: number;
  conversion_rate!: string;
  net_amount!: number;
  country!: string;
  constructor(dto: AnalyticsAdvancedImpl) {
    Object.assign(this, dto);
  }

  getPaymentsAmount = () => `${this.generatePriceFormat((this.amount_payments / 100).toFixed(2))}`;
  getRefundsAmount = () => `${this.generatePriceFormat((this.amount_chargebacks / 100).toFixed(2))}`;
  getChargebacksAmount = () => `${this.generatePriceFormat((this.amount_refunds / 100).toFixed(2))}`;
  getNetAmount = () => `${this.generatePriceFormat((this.net_amount / 100).toFixed(2))}`;
  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
