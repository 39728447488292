











import { Component, Vue } from 'vue-property-decorator';
import BoxAnalitycsLite from './BoxAnalitycsLite.vue';

import AnalyticsRepository, { queryDateType } from '@/repository/modules/analytics.repository';
import { AnalyticsGlobalsImpl } from '@/models/Analytics.model';

@Component({
  components: {
    BoxAnalitycsLite,
  },
})
export default class SectionInfoBlocks extends Vue {
  revenueToday: AnalyticsGlobalsImpl | null = null;
  revenueYesterday: AnalyticsGlobalsImpl | null = null;
  revenueLast7Days: AnalyticsGlobalsImpl | null = null;
  revenueLast30Days: AnalyticsGlobalsImpl | null = null;

  async mounted() {
    await this.getTodayAnalytics();
    await this.getYesterdayAnalytics();
    await this.getLast7DaysAnalytics();
    await this.getLast30DaysAnalytics();
  }

  async getTodayAnalytics() {
    this.revenueToday = await this.getMetrics(1);
  }
  async getYesterdayAnalytics() {
    this.revenueYesterday = await this.getMetrics(2);
  }
  async getLast7DaysAnalytics() {
    this.revenueLast7Days = await this.getMetrics(3);
  }
  async getLast30DaysAnalytics() {
    this.revenueLast30Days = await this.getMetrics(4);
  }

  async getMetrics(queryDate: queryDateType) {
    const analytics = await AnalyticsRepository.GetAnalyticsGlobal({
      date_type: queryDate,
    });
    return analytics;
  }
}
