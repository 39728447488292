






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { AnalyticsGlobalsImpl } from '@/models/Analytics.model';
@Component
export default class BoxAnalitycsLite extends Vue {
  @Prop({
    required: true,
    default: () => {
      return {
        users: 0,
        payments: 0,
        refunds: 0,
        chargebacks: 0,
      };
    },
  })
  groupAnalytics!: AnalyticsGlobalsImpl;

  @Prop({ type: String, required: true })
  title!: string;
  @Prop({ type: String, required: true })
  color!: string;

  get TotalUsers() {
    return this.groupAnalytics?.users;
  }
  get TotalPurchases() {
    return this.groupAnalytics?.payments;
  }
  get TotalRefunds() {
    return this.groupAnalytics?.refunds;
  }
  get TotalChargebacks() {
    return this.groupAnalytics?.chargebacks;
  }
  get Totalrevenue() {
    return (this.groupAnalytics?.revenue / 100).toFixed(2);
  }
}
