var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"origin":"center center","transition":"scale-transition","offset-y":"","max-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From/To","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function () { _vm.$refs.menu.save(_vm.date); this$1.getDataAnalytics(); }}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.calculateRowsAnalytics,"items-per-page":100},scopedSlots:_vm._u([{key:"item.amount_payments",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.getPaymentsAmount())+"€")])]}},{key:"item.amount_chargebacks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"red--text"},[_vm._v("-"+_vm._s(item.getChargebacksAmount())+"€")])]}},{key:"item.amount_refunds",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"yellow--text text--darken-4"},[_vm._v("-"+_vm._s(item.getRefundsAmount())+"€")])]}},{key:"item.net_amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"green--text text--darken-2 font-weight-bold"},[_vm._v(_vm._s(item.getNetAmount())+"€")])]}},{key:"item.conversion_rate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.conversion_rate)+"%")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }