











import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueCharts, { Bar } from 'vue-chartjs-typescript';

import BarChart from './BarChart.vue';

import AnalyticsRepository from '@/repository/modules/analytics.repository';
import { AnalyticsModelImpl } from '@/models/Analytics.model';
import { DateTransformerUtil, RangeDates } from '@/plugins/dateUtil';

@Component({
  components: {
    BarChart,
  },
})
export default class BoxChartView extends Vue {
  rowsAnalitycs: AnalyticsModelImpl[] = [];

  @Prop({ required: true, type: String })
  timeType!: string;
  /**
   * @groupType: [days, months, default, countries]
   */
  @Prop({ required: true, type: String })
  groupType!: string;

  @Prop({ required: true, type: String })
  titleChart!: string;

  mounted() {
    this.getMetrics(this.timeType, this.groupType);
  }

  @Watch('groupType')
  onPropertyChanged() {
    this.getMetrics(this.timeType, this.groupType);
  }

  async getMetrics(dateType: string, groupType: string) {
    // const { analytics } = await AnalyticsRepository.getAnalytics({
    //   payment_currency: '',
    //   payment_country: '',
    //   payment_platform: '',
    //   product_id: '',
    //   from: '',
    //   to: '',
    //   dateType: dateType,
    //   groupBy: groupType,
    // });
    // this.rowsAnalitycs = analytics;
  }

  get LabelsForChart() {
    return this.rowsAnalitycs.map((row: AnalyticsModelImpl) => row.payment_country ?? row.date);
  }
  get SeriePaymentsAmount() {
    return this.rowsAnalitycs.map((row: AnalyticsModelImpl) => (row.payments_amount / 100).toFixed(2));
  }
  get SerieTotalPayments() {
    return this.rowsAnalitycs.map((row: AnalyticsModelImpl) => row.total_payments);
  }
  get SerieRefundedPayments() {
    return this.rowsAnalitycs.map((row: AnalyticsModelImpl) => row.payments_refunds);
  }

  get SeriesTotalUsers() {
    return this.rowsAnalitycs.map((row: AnalyticsModelImpl) => row.total_users);
  }

  get DatasetPaymentAmount() {
    return {
      label: 'Income',
      backgroundColor: '#4CAF50',
      data: this.SeriePaymentsAmount,
    };
  }
  get DatasetTotalPayments() {
    return {
      label: 'Purchases',
      backgroundColor: '#3f51b5',
      data: this.SerieTotalPayments,
    };
  }
  get DatasetRefundsPayments() {
    return {
      label: 'Refunded',
      backgroundColor: '#ff5252',
      data: this.SerieRefundedPayments,
    };
  }

  get DatasetNewCustomers() {
    return {
      label: 'Customers',
      backgroundColor: '#ffc107',
      data: this.SeriesTotalUsers,
    };
  }

  get objectChart() {
    return {
      labels: this.LabelsForChart,
      datasets: [
        this.DatasetNewCustomers,
        this.DatasetTotalPayments,
        this.DatasetPaymentAmount,
        this.DatasetRefundsPayments,
      ],
    };
  }
}
