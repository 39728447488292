































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import UserTypes from '@/store/types/UserTypes';

@Component({
  components: {},
})
export default class Access extends Vue {
  formUserEmail = '';
  formUserPassword = '';
  errorText = '';

  @Action(`userAuthStore/${UserTypes.actions.LOGINADMIN}`) loginAdmin: any;

  get IsDisabledButton(): boolean {
    return this.validateEmail(this.formUserEmail) == false || this.validatePassword(this.formUserPassword) == false;
  }

  async validateForm() {
    this.errorText = '';
    const isValidEmail = this.validateEmail(this.formUserEmail);
    const isValidPassword = this.validatePassword(this.formUserPassword);
    if (isValidEmail && isValidPassword) {
      this.loginAdmin({
        admin_email: this.formUserEmail,
        admin_password: this.formUserPassword,
      }).catch((e: any) => this.ExceptionsParser(e));
    }
  }

  validatePassword(key: string) {
    return key.length >= 5;
  }

  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ExceptionsParser(e: any) {
    this.errorText = e.response?.data?.message || e.message || e.code || 'error...';
  }
}
